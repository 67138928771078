import {
  SIGNOUT,
  JOB_HOME,
  DASHBOARD_HOME,
  SEMINAR_HOME,
  COURSE_HOME,
  NOTIFICATION_HOME,
  HELP_AND_FEEDBACK_HOME,
  ABOUT_HOME,
  SETTINGS_HOME,
  PROFILE,
  INTERNSHIP_HOME,
  resources,
  searchJobs,
  appliedJobs,
  offeredJobs,
  dashboard,
  detailedJobs,
  DETAILED_JOB,
  DASHBOARD,
  HELP_AND_FEEDBACK,
  relationshipCompany,
  searchCompany,
  manageInvoices,
} from './routes';
import {
  BusinessCenterOutlined,
  BusinessRounded,
  DashboardRounded,
  DataUsageOutlined,
  Diversity3Rounded,
  FolderCopyRounded,
  Groups2Outlined,
  InfoRounded,
  LogoutOutlined,
  NotificationsRounded,
  PersonOutlineRounded,
  ReceiptRounded,
  SettingsRounded,
} from '@mui/icons-material';

export const APP_NAME = 'COMPANY_STUDENT_APP';

export const APP_BAR_HEIGHT = 50;
export const APP_SIDEBAR_WIDTH = 230;
export const DRAWER_WIDTH = 230;
export const DRAWER_WIDTH_MINI = 90;
export const DEFAULT_PAGINATION_PAGE_NO = 0;
export const DEFAULT_PAGINATION_COUNT = 10;
export const DEFAULT_PAGINATION = {
  data: [],
  page: DEFAULT_PAGINATION_PAGE_NO,
  count: DEFAULT_PAGINATION_COUNT,
  total: 0,
  next: false,
  previous: false,
};
export const DEFAULT_SORTING = 'ASC';
export const DEFAULT_ERROR_MESSAGE =
  'Something went wrong. Please refresh and try again.';
export const DEFAULT_DELETING_MESSAGE = 'Deleting...';
export const DEFAULT_DELETED_MESSAGE = 'Successfully deleted';

export const DRAWER_MENU = [
  {
    id: 1,
    title: 'Dashboard',
    url: DASHBOARD,
    icon: DashboardRounded,
  },
  {
    id: 2,
    title: 'Jobs',
    icon: BusinessCenterOutlined,
    items: [
      {
        id: 1,
        title: 'Search Jobs',
        url: JOB_HOME,
      },
      // {
      //   id: 2,
      //   title: 'Applied Jobs',
      //   url: appliedJobs(),
      // },
      {
        id: 3,
        title: 'Offered Jobs',
        url: offeredJobs(),
      },
    ],
  },
  {
    id: 3,
    title: 'Resources',
    icon: FolderCopyRounded,
    items: [
      {
        id: 4,
        title: 'My Resources',
        url: resources(),
      },
    ],
  },
  {
    id: 3,
    title: 'Company',
    icon: BusinessRounded,
    items: [
      {
        id: 4,
        title: 'My Company',
        url: relationshipCompany(),
      },
      {
        id: 4,
        title: 'Search Company',
        url: searchCompany(),
      },
    ],
  },
  {
    id: 3,
    title: 'Invoice',
    icon: ReceiptRounded,
    items: [
      {
        id: 4,
        title: 'Manage Invoices',
        url: manageInvoices(),
      },
    ],
  },
  {
    id: 4,
    title: 'Profile',
    url: PROFILE,
    icon: PersonOutlineRounded,
  },
  {
    id: 5,
    title: 'Notification',
    url: NOTIFICATION_HOME,
    icon: NotificationsRounded,
  },
  {
    id: 6,
    title: 'Help and Feedback',
    url: HELP_AND_FEEDBACK,
    icon: Groups2Outlined,
  },
  {
    id: 8,
    title: 'About',
    url: ABOUT_HOME,
    icon: InfoRounded,
  },
  {
    id: 8,
    title: 'Settings / 設定',
    url: SETTINGS_HOME,
    icon: SettingsRounded,
  },
  {
    id: 9,
    title: 'Sign Out',
    url: SIGNOUT,
    icon: LogoutOutlined,
    type: 'dialog',
  },
];

export const tableHeaderStyles = {
  fontSize: 'sm',
  fontFamily: 'Raleway',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  fontWeight: 'medium',
  flex: 1,
};

export const tableTextStyles = {
  fontFamily: 'Poppins',
  fontSize: 'xs',
  lineHeight: 1,
  fontWeight: 'normal',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flex: 1,
};

export const CARD_VIEW = 0;
export const LIST_VIEW = 1;
export const VIEW_TYPES_MAP = {
  [CARD_VIEW]: 'Card View',
  [LIST_VIEW]: 'List View',
};
export const VIEW_TYPES = Object.values(VIEW_TYPES_MAP);
export const DEFAULT_VIEW_TYPE = LIST_VIEW;
export const DEFAULT_VIEW_UNI_TYPE = CARD_VIEW;

export const paginationState = {
  previous: null,
  next: null,
  noOfPageItems: DEFAULT_PAGINATION_COUNT,
  page: DEFAULT_PAGINATION_PAGE_NO,
  total: DEFAULT_PAGINATION_COUNT * (DEFAULT_PAGINATION_PAGE_NO + 1),
  pageCount: DEFAULT_PAGINATION_PAGE_NO + 1,
  hasMore: true,
  loading: true,
  data: [],
};

export const PUBLISH_OPEN_TO_ALL = 0;
export const PUBLISH_TARGETED_JOB_POSTING = 1;
export const PUBLISH_TYPES_MAP = {
  [PUBLISH_OPEN_TO_ALL]: 'Open To All',
  [PUBLISH_TARGETED_JOB_POSTING]: 'Targeted Job Posting',
};

export const PUBLISH_SEMINAR_TYPES_MAP = {
  [PUBLISH_OPEN_TO_ALL]: 'Open To All',
  [PUBLISH_TARGETED_JOB_POSTING]: 'Targeted Seminar Posting',
};

export const PUBLISH_TYPES = Object.values(PUBLISH_TYPES_MAP);
export const PUBLISH_SEMINAR_TYPES = Object.values(PUBLISH_SEMINAR_TYPES_MAP);
export const DEFAULT_PUBLISH_TYPE = PUBLISH_OPEN_TO_ALL;

export const INTERVIEW_INVITE_INVITED = 'INVITED';
export const INTERVIEW_INVITE_SCHEDULED = 'SCHEDULED';
export const INTERVIEW_INVITE_ACCEPTED = 'ACCEPTED';
export const INTERVIEW_INVITE_REJECTED = 'REJECTED';
export const INTERVIEW_INVITE_NOT_SELECTED = 'NOT_SELECTED';
export const INTERVIEW_INVITE_SELECTED = 'SELECTED';
export const INTERVIEW_INVITE_RESCHEDULED = 'RESCHEDULED';
export const INTERVIEW_INVITE_DENIED = 'DENIED';
export const INTERVIEW_INVITE_RESCHEDULE = 'RESCHEDULE';

export const INTERVIEW_INIVITES_MAP = {
  [INTERVIEW_INVITE_REJECTED]: 'Rejected',
  [INTERVIEW_INVITE_INVITED]: 'Invited',
  [INTERVIEW_INVITE_ACCEPTED]: 'Accepted',
  [INTERVIEW_INVITE_RESCHEDULED]: 'Rescheduled',
  [INTERVIEW_INVITE_RESCHEDULE]: 'Reschedule',
  [INTERVIEW_INVITE_NOT_SELECTED]: 'Not Selected',
  [INTERVIEW_INVITE_DENIED]: 'Denied',
  [INTERVIEW_INVITE_SELECTED]: 'Selected',
  [INTERVIEW_INVITE_SCHEDULED]: 'Scheduled',
};

export const INTERVIEW_INIVITES_COLOR_MAP = {
  [INTERVIEW_INVITE_REJECTED]: 'error',
  [INTERVIEW_INVITE_INVITED]: 'info',
  [INTERVIEW_INVITE_ACCEPTED]: 'success',
  [INTERVIEW_INVITE_RESCHEDULED]: 'info',
  [INTERVIEW_INVITE_NOT_SELECTED]: 'error',
  [INTERVIEW_INVITE_SELECTED]: 'success',
  [INTERVIEW_INVITE_SCHEDULED]: 'success',
  [INTERVIEW_INVITE_DENIED]: 'error',
  [INTERVIEW_INVITE_RESCHEDULE]: 'info',
};

export const INTERVIEW_RESULT_REJECTED = 'REJECTED';
export const INTERVIEW_RESULT_SELECTED = 'SELECTED';

export const INTERVIEW_RESULT_MAP = {
  [INTERVIEW_RESULT_SELECTED]: 'Selected',
  [INTERVIEW_RESULT_REJECTED]: 'Rejected',
};

export const INTERVIEW_RESULT_COLOR_MAP = {
  [INTERVIEW_RESULT_SELECTED]: 'success',
  [INTERVIEW_RESULT_REJECTED]: 'error',
};

export const INTERVIEW_OFFER_LETTER_ACCEPTED = 'ACCEPTED';
export const INTERVIEW_OFFER_LETTER_DENIED = 'DENIED';
export const INTERVIEW_OFFER_LETTER_SENT = 'SENT';

export const INTERVIEW_OFFER_LETTER_MAP = {
  [INTERVIEW_OFFER_LETTER_DENIED]: 'Denied',
  [INTERVIEW_OFFER_LETTER_SENT]: 'Sent',
  [INTERVIEW_OFFER_LETTER_ACCEPTED]: 'Accepted',
};

export const INTERVIEW_OFFER_LETTER_COLOR_MAP = {
  [INTERVIEW_OFFER_LETTER_DENIED]: 'error',
  [INTERVIEW_OFFER_LETTER_SENT]: 'info',
  [INTERVIEW_OFFER_LETTER_ACCEPTED]: 'success',
};

export const REMINDERS = {
  0: 'Before 1 Week',
  1: 'Before 2 Weeks',
  2: 'Before 3 Weeks',
  3: 'None',
};

export const REMINDERS_OPTIONS = Object.keys(REMINDERS).map(o => ({
  value: o,
  text: REMINDERS[o],
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const SEMINAR_ATTENDED = 'ATTENDED';
export const SEMINAR_NOT_ACCEPTED = 'NOT ATTENDED';

export const SEMINAR_ATTENDANCE_MAP = {
  [SEMINAR_ATTENDED]: 'Attended',
  [SEMINAR_NOT_ACCEPTED]: 'Not Attended',
};

export const SEMINAR_ATTENDANCE_COLOR_MAP = {
  [SEMINAR_ATTENDED]: 'success',
  [SEMINAR_NOT_ACCEPTED]: 'error',
};

export const DEFAULT_LANGUAGE = 'ja';

export const LANGUAGES = [
  {
    text: 'Japanese',
    value: 'ja',
  },
  {
    text: 'English',
    value: 'en',
  },
];

export const DEFAULT_TIMEZONE = 'GMT+09:00';

export const TIMEZONES = [
  {
    value: 'GMT-12:00',
    text: 'Etc/GMT-12',
  },
  {
    value: 'GMT-11:00',
    text: 'Etc/GMT-11',
  },
  {
    value: 'GMT-11:00',
    text: 'Pacific/Midway',
  },
  {
    value: 'GMT-10:00',
    text: 'America/Adak',
  },
  {
    value: 'GMT-09:00',
    text: 'America/Anchorage',
  },
  {
    value: 'GMT-09:00',
    text: 'Pacific/Gambier',
  },
  {
    value: 'GMT-08:00',
    text: 'America/Dawson_Creek',
  },
  {
    value: 'GMT-08:00',
    text: 'America/Ensenada',
  },
  {
    value: 'GMT-08:00',
    text: 'America/Los_Angeles',
  },
  {
    value: 'GMT-07:00',
    text: 'America/Chihuahua',
  },
  {
    value: 'GMT-07:00',
    text: 'America/Denver',
  },
  {
    value: 'GMT-06:00',
    text: 'America/Belize',
  },
  {
    value: 'GMT-06:00',
    text: 'America/Cancun',
  },
  {
    value: 'GMT-06:00',
    text: 'America/Chicago',
  },
  {
    value: 'GMT-06:00',
    text: 'Chile/EasterIsland',
  },
  {
    value: 'GMT-05:00',
    text: 'America/Bogota',
  },
  {
    value: 'GMT-05:00',
    text: 'America/Havana',
  },
  {
    value: 'GMT-05:00',
    text: 'America/New_York',
  },
  {
    value: 'GMT-04:30',
    text: 'America/Caracas',
  },
  {
    value: 'GMT-04:00',
    text: 'America/Campo_Grande',
  },
  {
    value: 'GMT-04:00',
    text: 'America/Glace_Bay',
  },
  {
    value: 'GMT-04:00',
    text: 'America/Goose_Bay',
  },
  {
    value: 'GMT-04:00',
    text: 'America/Santiago',
  },
  {
    value: 'GMT-04:00',
    text: 'America/La_Paz',
  },
  {
    value: 'GMT-03:00',
    text: 'America/Argentina/Buenos_Aires',
  },
  {
    value: 'GMT-03:00',
    text: 'America/Montevideo',
  },
  {
    value: 'GMT-03:00',
    text: 'America/Araguaina',
  },
  {
    value: 'GMT-03:00',
    text: 'America/Godthab',
  },
  {
    value: 'GMT-03:00',
    text: 'America/Miquelon',
  },
  {
    value: 'GMT-03:00',
    text: 'America/Sao_Paulo',
  },
  {
    value: 'GMT-03:30',
    text: 'America/St_Johns',
  },
  {
    value: 'GMT-02:00',
    text: 'America/Noronha',
  },
  {
    value: 'GMT-01:00',
    text: 'Atlantic/Cape_Verde',
  },
  {
    value: 'GMT',
    text: 'Europe/Belfast',
  },
  {
    value: 'GMT',
    text: 'Africa/Abidjan',
  },
  {
    value: 'GMT',
    text: 'Europe/Dublin',
  },
  {
    value: 'GMT',
    text: 'Europe/Lisbon',
  },
  {
    value: 'GMT',
    text: 'Europe/London',
  },
  {
    value: 'UTC',
    text: 'UTC',
  },
  {
    value: 'GMT+01:00',
    text: 'Africa/Algiers',
  },
  {
    value: 'GMT+01:00',
    text: 'Africa/Windhoek',
  },
  {
    value: 'GMT+01:00',
    text: 'Atlantic/Azores',
  },
  {
    value: 'GMT+01:00',
    text: 'Atlantic/Stanley',
  },
  {
    value: 'GMT+01:00',
    text: 'Europe/Amsterdam',
  },
  {
    value: 'GMT+01:00',
    text: 'Europe/Belgrade',
  },
  {
    value: 'GMT+01:00',
    text: 'Europe/Brussels',
  },
  {
    value: 'GMT+02:00',
    text: 'Africa/Cairo',
  },
  {
    value: 'GMT+02:00',
    text: 'Africa/Blantyre',
  },
  {
    value: 'GMT+02:00',
    text: 'Asia/Beirut',
  },
  {
    value: 'GMT+02:00',
    text: 'Asia/Damascus',
  },
  {
    value: 'GMT+02:00',
    text: 'Asia/Gaza',
  },
  {
    value: 'GMT+02:00',
    text: 'Asia/Jerusalem',
  },
  {
    value: 'GMT+03:00',
    text: 'Africa/Addis_Ababa',
  },
  {
    value: 'GMT+03:00',
    text: 'Asia/Riyadh89',
  },
  {
    value: 'GMT+03:00',
    text: 'Europe/Minsk',
  },
  {
    value: 'GMT+03:30',
    text: 'Asia/Tehran',
  },
  {
    value: 'GMT+04:00',
    text: 'Asia/Dubai',
  },
  {
    value: 'GMT+04:00',
    text: 'Asia/Yerevan',
  },
  {
    value: 'GMT+04:00',
    text: 'Europe/Moscow',
  },
  {
    value: 'GMT+04:30',
    text: 'Asia/Kabul',
  },
  {
    value: 'GMT+05:00',
    text: 'Asia/Tashkent',
  },
  {
    value: 'GMT+05:30',
    text: 'Asia/Kolkata',
  },
  {
    value: 'GMT+05:45',
    text: 'Asia/Katmandu',
  },
  {
    value: 'GMT+06:00',
    text: 'Asia/Dhaka',
  },
  {
    value: 'GMT+06:00',
    text: 'Asia/Yekaterinburg',
  },
  {
    value: 'GMT+06:30',
    text: 'Asia/Rangoon',
  },
  {
    value: 'GMT+07:00',
    text: 'Asia/Bangkok',
  },
  {
    value: 'GMT+07:00',
    text: 'Asia/Novosibirsk',
  },
  {
    value: 'GMT+08:00',
    text: 'Etc/GMT+8',
  },
  {
    value: 'GMT+08:00',
    text: 'Asia/Hong_Kong',
  },
  {
    value: 'GMT+08:00',
    text: 'Asia/Krasnoyarsk',
  },
  {
    value: 'GMT+08:00',
    text: 'Australia/Perth',
  },
  {
    value: 'GMT+08:45',
    text: 'Australia/Eucla',
  },
  {
    value: 'GMT+09:00',
    text: 'Asia/Irkutsk',
  },
  {
    value: 'GMT+09:00',
    text: 'Asia/Seoul',
  },
  {
    value: 'GMT+09:00',
    text: 'Asia/Tokyo',
  },
  {
    value: 'GMT+09:30',
    text: 'Australia/Adelaide',
  },
  {
    value: 'GMT+09:30',
    text: 'Australia/Darwin',
  },
  {
    value: 'GMT+09:30',
    text: 'Pacific/Marquesas',
  },
  {
    value: 'GMT+10:00',
    text: 'Etc/GMT+10',
  },
  {
    value: 'GMT+10:00',
    text: 'Australia/Brisbane',
  },
  {
    value: 'GMT+10:00',
    text: 'Australia/Hobart',
  },
  {
    value: 'GMT+10:00',
    text: 'Asia/Yakutsk',
  },
  {
    value: 'GMT+10:30',
    text: 'Australia/Lord_Howe',
  },
  {
    value: 'GMT+11:00',
    text: 'Asia/Vladivostok',
  },
  {
    value: 'GMT+11:30',
    text: 'Pacific/Norfolk',
  },
  {
    value: 'GMT+12:00',
    text: 'Etc/GMT+12',
  },
  {
    value: 'GMT+12:00',
    text: 'Asia/Anadyr',
  },
  {
    value: 'GMT+12:00',
    text: 'Asia/Magadan',
  },
  {
    value: 'GMT+12:00',
    text: 'Pacific/Auckland',
  },
  {
    value: 'GMT+12:45',
    text: 'Pacific/Chatham',
  },
  {
    value: 'GMT+13:00',
    text: 'Pacific/Tongatapu',
  },
  {
    value: 'GMT+14:00',
    text: 'Pacific/Kiritimati',
  },
];
